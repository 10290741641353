import { run as runActions } from '../../../../store/workspaces/workspaces/run';

export const commandClick = (params: any, e: any): void => {
  const {
    key,
    id
  } = params;

  if (!key) return null;

  window.dispatchEvent(
    new CustomEvent(
      'custom-mousedown',
      {
        detail: {
          key: key,
          id: id,
          event: e
        }
      }
    )
  );
  if(!e.ctrlKey){
    runActions(key, id, null);
  }
};