import styled from 'styled-components';

export const FileViewerContainerStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 7px 0 0 0;
  border: 1px solid;
  border-color: rgb(225, 220, 220);
  border-radius: 5px;
  overflow-y: auto;

  > div {
    height: calc(100% - 7px);
    background: none;

    #image-renderer {
      background-image: none!important;
    }

    > div {
      overflow-y: hidden;

      > div {
        > div:nth-child(1) {
          justify-content: flex-start;
          padding: unset;
          background: none;
          box-shadow: none;

          > button:nth-child(5),
          > button:nth-child(4) {
            display: none;
          }

          > a,
          > button {
            height: 30px;
            width: 60px;
            margin-bottom: 8px;
            margin-right: 5px;
            padding: 2px 0px;
            background: linear-gradient(#f9f9f9, #c9c9c9);
            border-top: 1px solid #e9e9e9;
            border-right: 1px solid #f9f9f9;
            border-bottom: 1px solid #f9f9f9;
            border-left: 1px solid #e9e9e9;
            border-radius: 4px;
            box-shadow: none;
          }
        }

        > div:nth-child(2) {
          margin: 7px 0 0 0;
          border: 1px solid;
          border-color: rgb(225, 220, 220);
          border-radius: 5px;
          overflow-y: auto;
        }
      }
    }
  }

  .tiff-container,
  .tiff-container > div,
  .tiff-container > div > div {
    height: 100%;
    width: 100%;
  }

  .tiff-container > div > div {
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
`;

export default FileViewerContainerStyled;
