/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { HtmlEditor, Inject, MarkdownEditor, Resize, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { Image } from 'semantic-ui-react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import MessageContext from './contextmenu/Chat.message.context';
import { dateFromObject, formatDateTime, formatTime } from '../../../../utils/datetime';
import PropTypes from 'prop-types';
import { jwtDecrypt } from '../../../../api/services/tools/service.jwtDecrypt';
import { toast } from '../../../../utils/notification';

const ChatMessage = ({ navData, workspaceActions, post,  filterMode }) => {
  const [readOnly, setReadOnly] = useState(true);
  const [userData, setUserData] = useState(null);
  const currentText = true ;


  useEffect(() => {
    const fetchUser = async () => {
      const sessionData = JSON.parse(localStorage.getItem('session'));
      if (sessionData) {
        try {
          const decryptedData = await jwtDecrypt(sessionData);
          const user = decryptedData.data.user;
          setUserData(user);
        } catch (error) {
          toast.error('Error fetching user data:', error.message);
          throw error;
        }
      }
    };
    fetchUser(); 
    return () => {
      setUserData(null);
    };
  }, []);

  const editorRef = useRef(null);

  if (!post || !userData) {
    return null;
  }

  const renderDateTime = (post) => {

    const date: Date = dateFromObject({ ...post.createdAt, timezone: userData?.timezone });
    const today: Date = new Date();

    if (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    ) {
      return <p className='body-message-datetime'>{formatTime(date, userData?.timeFormat)}</p>;
    } else {
      return <p className='body-message-datetime'>{formatDateTime(date, userData?.dateFormat, userData?.timeFormat)}</p>;
    }
  };

  return (
    <div className={'body-history'}
      style={{ cursor: filterMode ? 'pointer' : null }}
      onClick={() => {
        if (filterMode) {
          alert(`get posts to post (id: ${post.id}) and scroll to post`);
        }
      }}
    >
      <div className="body-message-header">
        <div className="body-message-avatar">
          <Image avatar src={post.user?.image || ''} />
        </div>
        <p className="body-message-sender" id={'sender-' + post.id}>{post.user?.name || ''}</p>
      </div>
      <div className={`body-message  ${!readOnly ? 'body-message-edit' : null}`} >
        <RichTextEditorComponent
          className='body-message-text'
          readonly={readOnly}
          height={'auto'}
          value={post.text || ''}
          editorMode={'HTML'}
          key={'message-key-' + post.id}
          ref={editorRef}
        >
          <Inject services={[HtmlEditor, MarkdownEditor, Resize]} />
        </RichTextEditorComponent>
        {/* {navData.widgetData.menu?.elements ?
          <MessageContext
            post={post}
            navData={navData}
            workspaceActions={workspaceActions}
          /> : null
        } */}
        {renderDateTime(post)}
      </div>
      {!readOnly ?
        <div className='message-change-btn'>
          <ButtonComponent
            cssClass='e-custom message-change-btn-apply'
            style={{ textTransform: 'none' }}
            content={'Apply'}
            onClick={() => {
              setReadOnly(false);
              alert('TODO: update post by id');
            }}
          />

          <ButtonComponent
            cssClass='e-custom message-change-btn-reset'
            style={{ textTransform: 'none' }}
            content={'Abort'}
            onClick={() => {
              editorRef.current.value = currentText;
              setReadOnly(true);
            }}
          />
        </div>
        : null}
    </div>
  );
};

ChatMessage.propTypes = {
  navData: PropTypes.any,
  workspaceActions: PropTypes.any,
  post: PropTypes.object,
  setIsReply: PropTypes.func,
  setReplyTarget: PropTypes.func,
  filterMode: PropTypes.bool,
};

export default ChatMessage;
