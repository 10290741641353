import { toast, log } from '../../utils/notification';
import request from '../../request/request';

export const runStaticQuery = async (queryAlias: string, params: any) => {
  try {
    const response = await request<Request.Query, any>(true, 'POST', 'services/queries', {
      data:
        JSON.stringify({
          params,
          queryAlias
        }),
      session: localStorage.getItem('session')
    });

    response?.debug?.forEach(
      debug => {
        log( debug.step, { params: debug.params, response: debug.response }, debug.style);
      }
    );

    return response.data;
  } catch (error) {
    toast.error(queryAlias, error.message);
  }
};