// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  
  /* TODO: check to form css */
  .dynamicColumn {
    padding: 5px;
    display: inline-block;
    vertical-align: top;
    border-radius: 3px;
    height: 100%;
  }

  .main-content {
    height: calc(100vh - 96px);
  }
  
  .workspace-star-icon {
    color: rgb(232, 212, 2) !important;
  }
`, "",{"version":3,"sources":["webpack://./src/pages/module/workspaces/styles.css"],"names":[],"mappings":";EACE,4BAA4B;EAC5B;IACE,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,kCAAkC;EACpC","sourcesContent":["  \n  /* TODO: check to form css */\n  .dynamicColumn {\n    padding: 5px;\n    display: inline-block;\n    vertical-align: top;\n    border-radius: 3px;\n    height: 100%;\n  }\n\n  .main-content {\n    height: calc(100vh - 96px);\n  }\n  \n  .workspace-star-icon {\n    color: rgb(232, 212, 2) !important;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
