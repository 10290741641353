import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { runStaticQuery } from '../api/query/run.staticQuery';
import { run as runActions } from '../store/workspaces/workspaces/run';
import LoginPage from '../pages/login/page.login';
import ModulePage from '../pages/module/page.module';
import NotFoundPage from '../pages/notFound/page.notFound';
import ProtectedRoute from './Route.protected';
import { verifySessionToken } from '../api/services/tools/service.verifySession';
import { toast } from '../utils/notification';

const RoutesCustom = () => {
  const jwtString = window.location.pathname.split('app/')[1];
  const [isSessionValid, setIsSessionValid] = useState(true);

  useEffect(() => {
    const fetchWorkspaceData = async () => {
      const responseVerify = await verifySessionToken(localStorage.getItem('session'));

      if (responseVerify.expired) {
        toast.error('Session expired');
      }
      if (responseVerify.expired || responseVerify.error) {
        setIsSessionValid(false);
        return;
      }
      setIsSessionValid(true);

      const response = await runStaticQuery('workspace', null);
      const onLoadEvent = response?.workspace?.events?.find((event) => event?.type === 'onLoad') ?? null;

      if (!jwtString && onLoadEvent) {
        await runActions(onLoadEvent.key, null, null);
      }
    };

    fetchWorkspaceData();
  }, [jwtString]);

  useEffect(() => {
    const fetchSessionValidity = async () => {
      const response = await verifySessionToken(localStorage.getItem('session'));
      setIsSessionValid(!response.error && !response.expired);
    };

    fetchSessionValidity();
  }, []);


  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<Navigate to='/app' />} />
      {isSessionValid ? <Route path="/app" element={<ProtectedRoute><ModulePage /></ProtectedRoute>} />: null }
      {isSessionValid ? <Route path="/app/:jwtString" element={<ProtectedRoute><Navigate to='/app' /></ProtectedRoute>} /> : null }
      <Route path="/404" element={<NotFoundPage />} />
    </Routes>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <RoutesCustom />
    </BrowserRouter>
  );
};

export default App;
