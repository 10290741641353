import React from 'react';
import PropTypes from 'prop-types';
import MenuBarButton from './MenuBar.button';
import MenuBarDropdownButton from './MenuBar.dropdown';
import { WidgetConsumer } from '../../widgets/WidgetContext';
import { MenubarContainerStyled } from './styles';

const MenuBar = ({ menu }) => {

  const handleNewTab = (eventKey, currentDataID, args, e) => {
    window.dispatchEvent(
      new CustomEvent('custom-mousedown', {
        detail: {
          key: eventKey,
          id: currentDataID,
          event: e,
          context: 'menu',
        },
      })
    );
  };

  if (!menu.default) return null;

  return (
    <MenubarContainerStyled id={`menu-${menu.key}`} css={menu?.style?.code ?? ''}>
      {menu.default?.elements?.map((element, menuIndex) => {
        return (
          <WidgetConsumer key={`menu-dd-btn-wrapper${element.key}`}>
            {(widget) => {
              const commonProps = {
                element,
                widget,
              };

              return element.type === 'menu' ? (
                <MenuBarDropdownButton 
                  {...commonProps}
                  key={`menu-${element.type}-${element.key}`}
                  menuIndex={menuIndex} 
                />
              ) : element.type === 'button' ? (
                <MenuBarButton 
                  {...commonProps} 
                  key={`menu-${element.type}-${element.key}`}
                  handleNewTab={handleNewTab} />
              ) : null;
            }}
          </WidgetConsumer>
        );
      })}
    </MenubarContainerStyled>
  );
};

MenuBar.propTypes = {
  menu: PropTypes.object,
};

export default MenuBar;
