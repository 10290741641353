import { ContextMenuItemModel } from '@syncfusion/ej2-react-grids';

export const setCustomContextMenu = (navData) => {

  const customContextMenuItems = navData.widgetData.menu?.context?.elements.map(
    (o) => {
      return {
        text: o.label,
        target: '.e-content',
        id: o.events.find(event => event.type === 'onClick')?.key.toString()
      };
    }
  );

  const contextMenuItems: ContextMenuItemModel[] = [
    { text: 'PDF Export', target: '.e-content', id: 'grid_pdfexport' },
    { text: 'Excel Export', target: '.e-content', id: 'grid_excelexport' },
    { text: 'Print', target: '.e-content', id: 'grid_print' },
    customContextMenuItems ? customContextMenuItems[0] : ''
  ];

  return contextMenuItems;
};