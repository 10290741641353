import React, { useState, useRef, useEffect } from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent, ContextMenu, Filter, Inject, Page, Sort, ExcelExport, PdfExport, Resize, RowDD, Toolbar, InfiniteScroll, Aggregate } from '@syncfusion/ej2-react-grids';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { WidgetProvider } from '../../WidgetContext';
import { setCustomContextMenu } from '../services/setContextMenu';
import { contextMenuClick } from '../services/contextMenuClick';
// import { setDataCounter } from '../services/dataCounter/setDataCounter';
import { onRowDrag } from './events/onRowDrag';
import { useTranslation } from 'react-i18next';
import { setGridData } from '../services/setGridData';
import { getSettingsSort } from './settings';
// import DataCounter from '../services/dataCounter/dataCounter';
import { insertFilterValues, setFilterValues } from '../services/setFilterValues';
import { setKeyDownRemoveFilter } from '../services/setKeydownEvent';
import PropTypes from 'prop-types';
import { WidgetContainerStyled, WidgetContentStyled, WidgetLabelStyled } from '../../styles';
import { setFilterDateTimeTemplate } from '../services/setDateTimeFilter';
import { getAggregations } from '../services/aggregations';
import { GridContainerStyled } from '../styles';
import setClassNames from '../services/setClassNames';
import ColumnImageComponent from '../components/ColumnsImage';

const GridWidgetSort = ({ navData, actionsState }) => {
  const [gridData, setGridDataState] = useState(null);
  const [gridEditData, setGridEditData] = useState([]);
  const [timeFormat, setTimeFormat] = useState('');
  const [dateFormat, setDateFormat] = useState('');
  const { t } = useTranslation();
  // const [dataCounterString, setDataCounterString] = useState('');

  const gridSortInstance = useRef(null);

  const widgetContext = {
    instance: gridSortInstance.current,
    gridEditData: gridEditData,
    widgetData: navData.widgetData,
    actionsState: actionsState
  };


  useEffect(() => {
    const handleKeyDown = (event) => setKeyDownRemoveFilter(event, gridSortInstance.current);
    setGridDataState(null);
    setGridData(setGridDataState, setDateFormat, setTimeFormat, navData, actionsState);
    setTimeout(() => { insertFilterValues(gridSortInstance.current, navData); }, 500);

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      setGridDataState(null);
      setGridEditData([]);
      setDateFormat('');
      setTimeFormat('');
      // setDataCounterString('');
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navData.renderID, navData.widgetData.dataID, navData.widgetData.timestamp]);


  const created = () => {
    setGridEditData(gridData);
    window.removeEventListener('keydown', (event) => setKeyDownRemoveFilter(event, gridSortInstance.current));
  };

  const actionComplete = async (args) => {
    if (actionsState && navData) {
      await setFilterValues(args, navData.widgetData.key);
    }
    // await setDataCounter(args, { setState: setDataCounterString, t });
  };

  const rowDataBound = (args) => {
    setClassNames(args);
  };

  if (!navData?.widgetData || !gridData || !timeFormat || !dateFormat) {
    return null;
  }

  const settings = getSettingsSort(navData);
  const aggregations = getAggregations(navData);

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={widgetContext}>
        {navData.widgetData.menu ? (
          <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
        ) : null}
        {navData.widgetData.label !== '' ? <WidgetLabelStyled>{t(navData.widgetData.label)} </WidgetLabelStyled> : ''}
        <WidgetContentStyled>
          <GridContainerStyled css={navData.widgetData.style}>
            <GridComponent
              locale={JSON.parse(localStorage.getItem('language'))}
              ref={(g) => (gridSortInstance.current = g)}
              id={`grid-${navData.widgetData.key}`}
              key={`grid-${navData.widgetData.key}`}
              height={'100%'}
              rowHeight={28}
              dataSource={gridData}
              allowExcelExport={true}
              allowPdfExport={true}
              allowFiltering={true}
              allowResizing={true}
              allowPaging={navData.widgetData.allowPaging}
              enableInfiniteScrolling={!navData?.widgetData?.allowPaging}
              allowSorting={true}
              allowRowDragAndDrop={true}
              filterSettings={settings.filterSettingsMenu}
              contextMenuItems={navData ? setCustomContextMenu(navData) : null}
              pageSettings={navData?.widgetData?.allowPaging ? settings.pageSettings : settings.pageSettingsInfinite}
              created={created}
              rowDrop={(args) => onRowDrag(args, gridData, setGridEditData)}
              contextMenuClick={(args) => contextMenuClick(args, gridSortInstance.current, navData ? setCustomContextMenu(navData) : null)}
              rowDropSettings={settings.rowDropSettings}
              actionComplete={actionComplete}
              aggregates={aggregations}
              rowDataBound={rowDataBound}
            >
              <ColumnsDirective>
                {navData.widgetData.columns.map((column) => {
                  switch (column.type) {
                    case 'boolean':
                      return null;
                    case 'code':
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={{ class: [settings.customAttributes.class, 'code-cell'] }}
                          textAlign={'Center'}
                        />
                      );
                    case 'datetime':
                    case 'date':
                      // eslint-disable-next-line no-case-declarations
                      const filterTemplateDateTime = setFilterDateTimeTemplate({ dateFormat, timeFormat }, column, gridEditData, setGridEditData);

                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          field={column.name}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          filterBarTemplate={filterTemplateDateTime}
                          visible={!column.hide}
                          disableHtmlEncode={false}
                          isPrimaryKey={column.name === 'id'}
                          type={column.type === 'date' ? 'date' : 'dateTime'}
                          format={`${dateFormat}${column.type === 'datetime' ? ` ${timeFormat}` : ''}`}
                        />
                      );
                    case 'binary':
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={{ class: [settings.customAttributes.class, 'code-cell'] }}
                          template={(row) => <ColumnImageComponent row={row} />}
                          textAlign={'Center'}
                        />
                      );
                    default:
                      return (
                        <ColumnDirective
                          key={`field-${column.id}`}
                          field={column.name}
                          width={column.minWidth}
                          minWidth={column.minWidth}
                          maxWidth={column.maxWidth}
                          customAttributes={column.name === 'id' ? { class: [settings.customAttributes.class, 'id-cell'] } : settings.customAttributes}
                          headerText={t(column.label.charAt(0).toUpperCase() + column.label.slice(1))}
                          filter={settings.filterMenu}
                          visible={!column.hide}
                          disableHtmlEncode={false}
                          isPrimaryKey={column.name === 'id'}
                          type={'string'}
                        />
                      );
                  }
                })}
              </ColumnsDirective>
              <Inject services={[Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Resize, RowDD, Toolbar, InfiniteScroll, Sort, Aggregate]} />
            </GridComponent>
          </GridContainerStyled>
        </WidgetContentStyled>
        {/* {!navData?.widgetData?.allowPaging && (
          <DataCounter
            counter={dataCounterString}
          />
        )} */}
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

GridWidgetSort.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GridWidgetSort;
