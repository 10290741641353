import { jwtDecrypt } from '../tools/service.jwtDecrypt';
import store from '../../../store/store';
import { toast } from '../../../utils/notification';
import request from '../../../request/request';


export const setWorkspacesPinnedAPI = async (workspaceIndex, pinned) => {
  try {
    const workspace = store.getState().workspaces.instances[workspaceIndex];

    const data = pinned
      ? {
        ...workspace,
        data: workspace.breadcrumb.currentPage ?? workspace.data,
        isActive: false,
        widgets: {},
        modals: {},
      }
      : {
        uuid: workspace?.uuid,
      };

    const sessionData = JSON.parse(localStorage.getItem('session'));
    const userUuid = (await jwtDecrypt(sessionData)).data.user.uuid;

    const response = await request<Request.PinWorkspace, any>(true, 'POST', 'services/workspaces/set-pinned',  { data, pinned, user: { uuid: userUuid } });

    return response;
  } catch (error) {
    toast.error('setWorkspacesPinnedAPI:',  error.message);
  }
};
