import initialState from './initialState';
import types from './types';
import * as WorkspaceReducers from './workspaces/reducer';
import * as WidgetReducers from './widgets/reducer';
import * as BreadcrumbReducers from './breadcrumb/reducer';
import * as ModalReducers from './modals/reducer';
import * as ChatReducers from './chat/reducer';
import * as ModuleReducers from './modules/reducer';
import * as NavigationReducers from './navigation/reducer';

const reducersMap = {
  // Workspace Actions
  [types.WORKSPACE_OPEN]: WorkspaceReducers.openWorkspaceReduce,
  [types.WORKSPACE_CLOSE]: WorkspaceReducers.removeWorkspaceReduce,
  [types.WORKSPACE_SET_ACTIVE_INDEX]: WorkspaceReducers.setActiveWorkspaceReduce,
  [types.WORKSPACE_UPDATE_ACTIONS]: WorkspaceReducers.updateWorkspaceReduce,
  [types.WORKSPACE_SET_IS_NEW]: WorkspaceReducers.setWorkspaceIsNewReduce,
  [types.WORKSPACE_RENAME]: WorkspaceReducers.renameWorkspaceReduce,
  [types.WORKSPACE_ACTIONS_PENDING]: WorkspaceReducers.setWorkspacePendingReduce,
  [types.WORKSPACE_ACTIONS_PINNED]: WorkspaceReducers.setWorkspacePinnedReduce,
  [types.ADD_PINNED_WORKSPACES]: WorkspaceReducers.addPinnedWorkspacesReduce,
  [types.WORKSPACE_UPDATE_PLACEMENT]: WorkspaceReducers.updateWorkspacesPlacmentReduce,

  // Widget Actions
  [types.WIDGETS_UPDATE]: WidgetReducers.updateWidgetsReduce,
  [types.WIDGETS_REMOVE_MODAL_WIDGET]: WidgetReducers.removeModalWidgetReduce,
  // Breadcrumb Actions
  [types.BREADCRUMB_UPDATE_PAGES]: BreadcrumbReducers.updateBreadcrumbPageReduce,
  [types.BREADCRUMB_ADD_PAGE]: BreadcrumbReducers.addBreadcrumbPageReduce,
  [types.BREADCRUMB_GO_BACK]: BreadcrumbReducers.goBackBreadcrumbReduce,
  [types.BREADCRUMB_CLEAR]: BreadcrumbReducers.clearBreadcrumbReduce,
  [types.BREADCRUMB_SET_GRID_FILTER]: BreadcrumbReducers.setFilterBreadcrumbReduce,
  // Modal Actions
  [types.OPEN_MULTIUPLOAD_MODAL]: ModalReducers.openMultiUploadReduce,
  [types.CLOSE_MULTIUPLOAD_MODAL]: ModalReducers.closeMultiUploadReduce,
  [types.SET_TEXT_SELECTION]: ModalReducers.setTextCursorModalReduce,
  [types.OPEN_RICHTEXT_MODAL]: ModalReducers.openRichtextModalReduce,
  [types.CLOSE_RICHTEXT_MODAL]: ModalReducers.closeRichtextModalReduce,
  [types.SET_DIALOG_OPEN]: ModalReducers.openDialogReduce,
  [types.SET_MODAL_OPEN]: ModalReducers.openModalReduce,
  // Chat Actions
  [types.CHAT_SET_OPEN]: ChatReducers.setChatOpenReduce,
  [types.CHAT_SET_POSTS]: ChatReducers.setChatPostsReduce,
  [types.CHAT_SET_FILTER_MODE]: ChatReducers.setChatFilterMode,
  [types.CHAT_ADD_POSTS_TOP]: ChatReducers.addPostTopReduce,
  [types.CHAT_ADD_POSTS_BOTTOM]: ChatReducers.addPostBottomReduce,
  [types.BADGE_SET_VISIBLE]: ChatReducers.setBadgeVisibleReduce,
  [types.CHAT_SET_VISIBLE]: ChatReducers.setChatVisibleReduce,
  // Module Actions
  [types.MODULES_SET]: ModuleReducers.setModulesReduce,
  [types.MODULES_SET_SELECTED]: ModuleReducers.setSelectedModuleReduce,
  // Navigation Actions
  [types.GET_NAVIGATION]: NavigationReducers.setNavigationReduce,
};

const reducer = (state = initialState, action) => {
  const reduceFunction = reducersMap[action.type];
  return reduceFunction ? reduceFunction(state, action) : state;
};

export default reducer;
