import { toast } from '../../../utils/notification';
import { jwtDecrypt } from '../tools/service.jwtDecrypt';
import request from '../../../request/request';


export const setWorkspacesSequenceAPI = async (pinnedWorkspacesUuids) => {
  try {
    const sessionData = JSON.parse(localStorage.getItem('session'));
    const userUuid = (await jwtDecrypt(sessionData)).data.user.uuid;

    const response = await request<Request.SequenceWorkspace, any>(true, 'POST', 'services/workspaces/set-pinned-sequence',  { pinnedWorkspacesUuids, user: { uuid: userUuid } });

    return response;
  } catch (error) {
    toast.error('setWorkspacesPinnedAPI:',  error.message);
  }
};
