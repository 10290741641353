import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import '../styles.css';

const LinkForm = ({setExternURL}) => {
  const [inputValue, setInputValue] = useState('');
  const [url, setUrl] = useState('');
  const  modalStates = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive).modals);

  useEffect(() => {
    setUrl(modalStates.selectedText.href);
    setInputValue(modalStates.imageDialog.params.selectedText);
  }, [modalStates]);

  return (
    <div id="sample" className="list-content">
      <input
        className="e-input textbox-external-link"
        type="url"
        id="textbox"
        placeholder="External URL"
        onChange={(e) => {
          setUrl(e.target.value);
          setExternURL({ url: e.target.value, type: 'link' });
        }}
        value={url}
      />
      <input
        className="e-input textbox-link-text"
        type="text"
        id="textbox"
        placeholder="Text"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        value={inputValue}
      />
    </div>
  );
};

LinkForm.propTypes = {
  setExternURL: PropTypes.func
};

export default connect()(LinkForm);
