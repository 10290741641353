import { runStaticQuery } from '../../query/run.staticQuery';

export const addNodesGraph = async (params: any, componentParams: any) => {
  await runStaticQuery('style', { nodes: params.nodes, relations: params.relations, id: params.id })
    .then((response: any) => {
      if (componentParams) {
        componentParams.addNodesCytoscape(response.objects, componentParams.itemPosition);
      }
      return response;
    });
};
