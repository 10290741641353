import request from '../../../request/request';
import { toast } from '../../../utils/notification';

export const logout = async () => {
  const response = await request(
    true,
    'POST',
    'services/user/logout'
  );
  
  if (response.error)
    toast.error('', response.message);

  localStorage.removeItem('session');
  window.location.reload();
};
