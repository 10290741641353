
export const updateBreadcrumbPageReduce = (state, action) => {
  const { instances } = state;
  return {
    ...state,
    instances: instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          breadcrumb: {
            shiftHelper: instance.breadcrumb.shiftHelper,
            historyPages: action.historyPagesData,
            currentPage: action.currentPageData,
          }
        };
      }
      return instance;
    }),
  };
};


export const addBreadcrumbPageReduce = (state, action) => {
  const { instances } = state;
  return {
    ...state,
    instances: instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          breadcrumb: {
            shiftHelper: instance.breadcrumb.historyPages.length >= 10 ? [ instance.breadcrumb.historyPages.shift(), ...instance.breadcrumb.shiftHelper ]: instance.breadcrumb.shiftHelper,
            historyPages: [...instance.breadcrumb.historyPages, action.data],
            currentPage: action.data
          }
        };
      }
      return instance;
    }),
  };
};

export const goBackBreadcrumbReduce = (state, action) => {
  const { instances } = state;
  const activeBreadcrumbInstance = instances.find(instance => instance.isActive)?.breadcrumb || { shiftHelper: [], historyPages: [], currentPage: null };
  const newHistory = activeBreadcrumbInstance.historyPages.splice(0, activeBreadcrumbInstance.historyPages.length - (activeBreadcrumbInstance.historyPages.length - action.pageIndex - 1));

  while (newHistory.length < 10 && activeBreadcrumbInstance.shiftHelper.length > 0) {
    newHistory.unshift(activeBreadcrumbInstance.shiftHelper.shift());
  }
  
  return {
    ...state,
    instances: instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          breadcrumb: {
            shiftHelper: activeBreadcrumbInstance.shiftHelper,
            historyPages: newHistory,
            currentPage: newHistory[newHistory.length - 1]
          }
        };
      }
      return instance;
    }),
  };
};

export const clearBreadcrumbReduce = (state) => {
  const { instances } = state;
  return {
    ...state,
    instances: instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          breadcrumb: {
            shiftHelper: [],
            historyPages: [],
            currentPage: []
          }
        };
      }
      return instance;
    }),
  };
};

export const setFilterBreadcrumbReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          breadcrumb: {
            ...instance.breadcrumb,
            historyPages: instance.breadcrumb.historyPages.map((page, pageIndex) => {
              if (pageIndex === instance.breadcrumb.historyPages.length - 1) {
                return {
                  ...page,
                  grid: {
                    filterValues: action.filterValues,
                    key: action.key,
                  },
                };
              }
              return page;
            }),
          },
        };
      }
      return instance;
    }),
  };
};
    
  