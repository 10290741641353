import { log } from '../../../utils/notification';
import { runStaticQuery } from '../run.staticQuery';
import { jwtDecrypt } from '../../services/tools/service.jwtDecrypt';

export const getThemes = async () => {

  let userUUID = null;

  await jwtDecrypt(JSON.parse(localStorage.getItem('session')))
    .then((response) => {
      userUUID = response.data.user.uuid;
    })
    .catch((error) => {
      console.log('jwtDecrypt error', error);
    });

  try {
    const parameters = { user: userUUID };
    return runStaticQuery('theme', parameters);
  } catch (error) {
    log('StoredProcedure (alias getThemes)', { params: {}, response: { message: error.message } }, 'Error');
  }

};

