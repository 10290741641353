import { log } from '../../../../utils/notification';
import { runStaticQuery } from '../../../../api/query/run.staticQuery';
import { jwtDecrypt } from '../../../../api/services/tools/service.jwtDecrypt';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const service = async (storeName: string, params: any) => {

  let userUUID = null;

  await jwtDecrypt(JSON.parse(localStorage.getItem('session')))
    .then((response) => {
      userUUID = response.data.user.uuid;
    })
    .catch((error) => {
      console.log('jwtDecrypt error', error);
    });

  try {
    return runStaticQuery('target', { ...params, user: userUUID });
  } catch (error) {
    log('StoredProcedure (alias linkService)', { params: params, response: { message: error.message } }, 'Error');
  }
};
