import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { log, toast } from '../utils/notification';
import { verifySessionToken } from '../api/services/tools/service.verifySession';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jwtDecrypt } from '../api/services/tools/service.jwtDecrypt';

type RequestMethod = 'DELETE' | 'GET' | 'HEAD' | 'OPTIONS' | 'PATCH' | 'POST' | 'PUT' | 'TRACE';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      toast.warning('Request', 'Timeout');
    } else if (error.code === 'ECONNREFUSED') {
      toast.warning('Network', 'Connection Refused');
    } else if (error.code === 'ERR_NETWORK ') {
      toast.error('Network', 'Backend not available');
    } else {
      log('Request', {response: error.message}, 'Error');
    }
    log('Request', {response: error.message}, 'Error');
    return Promise.reject(error);
  }
);

/**
 * Sends a HTTP request to the backend API.
 * @param {boolean} tokenAccess - Indicates whether the request requires an access token.
 * @param {RequestMethod} method - The HTTP request method (e.g., GET, POST).
 * @param {string} endpoint - The API endpoint to send the request to.
 * @param {Record<string, any>} parameters - Parameters to be sent in the request body or query params.
 * @param {Record<string, any>} [headers] - Additional headers for the request.
 * @param {any} [responseType] - The expected response type from the server.
 * @returns {Promise<Record<string, any>>} - A Promise that resolves with the response data.
 */
const request = async <RequestType extends Record<string, any>, ResponseType extends Record<string, any> = Record<string, any>>(
  tokenAccess: boolean,
  method: RequestMethod,
  endpoint: string,
  parameters: RequestType = {} as RequestType,
  headers?: any,
  responseType?: any
): Promise<ResponseType> => {
  try {
    if (tokenAccess) {
      const response = await verifySessionToken(localStorage.getItem('session'));
      if (response.expired) {
        toast.warning(response.message);
        localStorage.removeItem('session');
        localStorage.setItem('expired', JSON.stringify(true));
        window.location.reload();
        return;
      }
      if (response.error) {
        toast.warning(response.message);
        return;
      }
    }


    const baseUrl = process.env.REACT_APP_BACKEND_URL;
    if (!baseUrl) {
      throw new Error('API_BASE_URL is not defined in your .env file.');
    }

    const hasBody = method === 'PATCH' || method === 'POST' || method === 'PUT';

    const csrfToken = JSON.parse(localStorage.getItem('csrfToken'));
    
    const config: AxiosRequestConfig = {
      method,
      url: endpoint,
      params: hasBody ? undefined : parameters,
      data: hasBody ? parameters : undefined,
      headers: { ...(headers ?? {}), 'Authorization': `${csrfToken}` },
      responseType: responseType
    };

    try {
      const response: AxiosResponse<ResponseType> = await api.request(config);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  } catch (error) {
    toast.error('request error', error.message);
  }
};

export default request;
