import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header/Header';
import WorkspaceComponent from './workspace';
import { useDispatch, useSelector } from 'react-redux';
import { addPinnedWorkspaces, removeWorkspace, setActiveWorkspace, setIsNewWorkspace, updateWorkspacesPlacement } from '../../../store/workspaces/workspaces/actions';
import { run as runActions } from '../../../store/workspaces/workspaces/run';
import WorkspaceTabTextbox from './workspace.textbox';
import { getModules } from '../../../store/workspaces/modules/actions';
import { WorkspaceButtonBarStyled, WorkspaceButtonContainerStyled, WorkspaceContentContainerStyled } from './styles';
import ContextMenuWorkspace from './workspace.dropdown';
import { getWorkspacesPinnedAPI } from '../../../api/services/workspaces/getWorkspacesPinned';
import { toast } from '../../../utils/notification';
import './styles.css';
import store from '../../../store/store';
import { setWorkspacesSequenceAPI } from '../../../api/services/workspaces/setPinnedWorkspaceSequence';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const WorkspacesMainstage = () => {
  const workspaces = useSelector((state: any) => state.workspaces);
  const dispatch = useDispatch<any>();
  const [dragEndKey, setDragEndKey] = useState(0);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const response = await getWorkspacesPinnedAPI();
        const pinnedWorkspaces = response.data.map(JSON.parse);
        dispatch(addPinnedWorkspaces(pinnedWorkspaces));
      } catch (error) {
        toast.error('Error fetching workspaces:', error.message);
        throw error;
      }
    };
  
    fetchWorkspaces();
  }, []);
  

  const handleTabClick = async (workspace, index) => {
    await dispatch(setActiveWorkspace(index));
  
    if (workspace.isNewTab) {
      await dispatch(getModules());
      await runActions(workspace.data.key, workspace.data.id, workspace.data);
      await dispatch(setIsNewWorkspace(index, false));
    }
  };

  const handleTabCloseClick = async (index) => {
    await dispatch(removeWorkspace(index));
    const newIndex = index === 0 ? 0 : index - 1;
    await dispatch(setActiveWorkspace(newIndex));
    const newActiveWorkspace = index === 0
      ? workspaces.instances.find(workspace => workspace.isNewTab)
      : workspaces.instances[newIndex];
  
    if (newActiveWorkspace?.isNewTab) {
      const { key, id } = newActiveWorkspace.data;
      await runActions(key, id, newActiveWorkspace.data);
      await dispatch(setIsNewWorkspace(newIndex, false));
    }
  };

  const onDragEnd = async(result) => {

    if (!result.destination) return;
    
    const draggedWorkspace = workspaces.instances.splice(result.source.index, 1)[0];
    workspaces.instances.splice(result.destination.index, 0, draggedWorkspace);

    const pinnedWorkspaceUUIDs = workspaces.instances
      .filter(instance => instance.isPinned) 
      .map(instance => instance.uuid); 

    await setWorkspacesSequenceAPI(pinnedWorkspaceUUIDs);
    store.dispatch(updateWorkspacesPlacement(workspaces.instances));
    setDragEndKey((prevKey) => prevKey + 1);
  };


  return (
    <>
      <DragDropContext key={dragEndKey} onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <WorkspaceButtonBarStyled
              ref={provided.innerRef}
            >
              {workspaces.instances.map((workspace, index) => {
                if (workspace.isOpen) {
                  return (
                    <Draggable key={index} draggableId={workspace.uuid} index={index}>
                      {(provided) => (
                        <WorkspaceButtonContainerStyled
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={index} 
                          active={+workspace.isActive}
                          id={`workspace-cm-target-${index}`}
                          data-id={workspace?.uuid}
                        > 
                          <span className="e-icons e-drag-and-drop"></span>
                          {workspace.isPinned ? <span className="e-icons e-star-filled workspace-star-icon"></span>: null}
                          <button
                            key={index}
                            className='workspace-button'
                            onClick={() => handleTabClick(workspace, index)}
                          >
                            <WorkspaceTabTextbox workspace={workspace} />
                            <ContextMenuWorkspace workspaceIndex={index}/>
                          </button>
                          {workspaces.instances.length > 1 && (
                            <button
                              className='workspace-button e-icons e-close'
                              onClick={() => setTimeout(() => handleTabCloseClick(index), 500)}
                            />
                          )}
                        </WorkspaceButtonContainerStyled>
                      )}
                    </Draggable>
                  );
                } else {
                  return null; 
                }
              })}
            </WorkspaceButtonBarStyled>
          )}
        </Droppable>
      </DragDropContext>
      <Header />
      {workspaces.instances.map((workspace, index) => (
        <WorkspaceContentContainerStyled
          key={index}
          active={+workspace.isActive}
        >
          <WorkspaceComponent workspace={workspace} />
        </WorkspaceContentContainerStyled>
      ))}
    </>
  );
};


export default WorkspacesMainstage;