import types from '../types';
import { jwtDecrypt } from '../../../api/services/tools/service.jwtDecrypt';
import { runStaticQuery } from '../../../api/query/run.staticQuery';
import { verifySessionToken } from '../../../api/services/tools/service.verifySession';
import { toast } from '../../../utils/notification';

const setNavigationTreeData = (data: any) => ({
  type: types.GET_NAVIGATION,
  data,
});


export const setTreeData = (key: number) => async (dispatch: any) => {

  try {
    const response = await verifySessionToken(localStorage.getItem('session'));
    if (response.error) {
      toast.error('action.tsx', response.message);
      // localStorage.removeItem('session');
      // window.location.reload();
    }
    else if (response.expired) {
      toast.warning(response.message);
      localStorage.removeItem('session');
      localStorage.setItem('expired', JSON.stringify(true));
      window.location.reload();
    } else {
      const decryptedSession = await jwtDecrypt(JSON.parse(localStorage.getItem('session')));
      const params = { key, uuid: decryptedSession.data.user.uuid };
      const navigationResponse = await runStaticQuery('navigation', params);

      if (navigationResponse) {
        dispatch(setNavigationTreeData(navigationResponse.navigation));
      }
    }
  } catch (error) {
    console.log('getNavigation error:', error);
  }
};

