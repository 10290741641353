import React, { useRef } from 'react';
import { Grid } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import Logo from '../Logo/Logo';
import ModulesDropdown from '../dropdown/Modules.dropdown';
import ModulesTreeView from '../treeView/Modules.treeview';
import NotificationMessage from '../../errorBoundary/ErrorBoundary';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import './styles.css';
import PropTypes from 'prop-types';


const ModuleNavigation = ({ workspace, isMenuOpen, handleLeftSidebar }) => {
  const themes = useSelector((state: any) => state.getThemes);
  const sidebarRef = useRef(null);

  return (
    <Grid.Column width={3} style={{ height: '100%' }}>
      <SidebarComponent
        ref={sidebarRef}
        style={{ backgroundColor: themes?.data?.colors?.primary?.hex }}
        width={isMenuOpen ? '300' : '30'}
        enableGestures={false}
      >
        <div
          className={`float-left e-icons ${isMenuOpen ? 'close-navigation-icon' : 'open-navigation-icon'}`}
          style={{ cursor: 'pointer', color: themes?.data?.colors?.primary?.contrast }}
          onClick={() => handleLeftSidebar()}
        ></div>
        <div className={`navigation-content ${isMenuOpen ? '' : 'hide'}`}>
          <NotificationMessage>
            <Logo />
          </NotificationMessage>

          <NotificationMessage>
            <ModulesDropdown workspace={workspace}/>
          </NotificationMessage>

          <NotificationMessage>
            <ModulesTreeView workspace={workspace} />
          </NotificationMessage>
        </div>
      </SidebarComponent>
    </Grid.Column>
  );
};

ModuleNavigation.propTypes = {
  isMenuOpen: PropTypes.bool,
  handleLeftSidebar: PropTypes.func,
  workspace: PropTypes.object
};

export default ModuleNavigation;
