import React, { useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { closeRichtextDialog } from '../../../../../store/workspaces/modals/actions';
import { Tab } from 'semantic-ui-react';
import { toast } from '../../../../../utils/notification';
import { runStaticQuery } from '../../../../../api/query/run.staticQuery';
import DiagramGrid from './grids/grid.diagram';
import ImageGrid from './grids/grid.image';
import LinkGrid from './grids/grid.link';
import LinkForm from './forms/form.link';
import ImageForm from './forms/form.image';
import './styles.css';

const RichtextDialog = () => {
  const [selectedElement, setSelectedElement] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [externURL, setExternURL] = useState(null);
  const modalStates: any = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive)?.modals);
  const dispatch = useDispatch();
  const dialogInstance = useRef(null);

  const buttons = [
    {
      buttonModel: {
        content: 'ok',
        cssClass: 'e-flat',
        isPrimary: true
      },
      click: async () => {
        const inputTextElement: any = document.querySelector('input.textbox-link-text');

        if (inputTextElement?.value === '') {
          toast.error('', 'Text input is missing');
          return null;
        }
        if (externURL !== null) {
          let url;
          if (externURL.url !== '') {
            try {
              url = new URL(externURL.url);
            } catch (_) {
              toast.error('', 'URL is not valid');
              return null;
            }
          }
          switch (externURL.type) {
            case 'image':
              if (!/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)) {
                toast.error('', 'URL is not a valid image');
                return null;
              }
              modalStates.imageEvent(selectedElement, url?.href);
              dispatch(closeRichtextDialog());
              return null;
            case 'link':
              modalStates.linkEvent(null, inputTextElement?.value, url.href);
              dispatch(closeRichtextDialog());
              return null;
            default:
              return null;
          }
        }

        const parameters = {
          ...selectedElement,
          link: {
            name: inputTextElement?.value ?? selectedElement?.target?.name ?? selectedElement?.name
          }
        };

        runStaticQuery('mergeElements', parameters)
          .then(async (response) => {
            if (!response.error)
              console.log(
                '%c StoredProcedure (mergeElements) %c',
                'background: #F9E79F; color: #000',
                'background: transparent; color: black;',

                {
                  params: parameters,
                  response: { uuid: response.uuid }
                }
              );
            switch (modalStates.eventType) {
              case 'image_grid':
                return modalStates.imageEvent(selectedElement);
              case 'link_grid':
                return modalStates.linkEvent(selectedElement, inputTextElement?.value ?? selectedElement.text, null);
              case 'diagram_grid':
                return modalStates.diagramEvent(selectedElement);
              default:
                return null;
            }
          }
          )
          .finally(
            () => {
              dispatch(closeRichtextDialog());
            }
          )
          .catch((error) => {
            toast.error('RichtextDialog:  ', error.message);
            throw error;
          }

          );
      }
    },
    {
      buttonModel: {
        content: 'cancel',
        cssClass: 'e-flat',
        isPrimary: false,
      },
      click: () => {
        dispatch(closeRichtextDialog());
      }
    },
  ];

  const getListRender = (type, tabType) => {
    switch (type) {
      case 'image_grid':
        return <ImageGrid params={modalStates.imageDialog.params} setSelectedElement={setSelectedElement} tabType={tabType} />;
      case 'link_grid':
        return <LinkGrid params={modalStates.imageDialog.params} setSelectedElement={setSelectedElement} tabType={tabType} />;
      case 'diagram_grid':
        return <DiagramGrid params={modalStates.imageDialog.params} setSelectedElement={setSelectedElement} tabType={tabType} />;
      case 'link':
        return <LinkForm setExternURL={setExternURL} />;
      case 'image':
        return <ImageForm setExternURL={setExternURL} />;
      default:
        return null;
    }
  };

  const setHeader = (type) => {
    switch (type) {
      case 'image_grid':
        return 'get linked image';
      case 'link_grid':
        return 'get link';
      case 'diagram_grid':
        return 'get diagram';
      case 'link':
        return 'set external link';
      case 'image':
        return 'set external image';
      default:
        return null;
    }
  };

  if (!modalStates.imageDialog.params) return null;

  const panes = [
    { menuItem: 'New', render: () => <Tab.Pane> {getListRender(modalStates.eventType, 'new')}</Tab.Pane> },
    { menuItem: 'Existing', render: () => <Tab.Pane> {getListRender(modalStates.eventType, 'existing')}</Tab.Pane> }
  ];

  return (
    <div id='container'>
      <DialogComponent
        open={modalStates.imageDialog.isOpen}
        id={`dialog-${!modalStates.imageDialog.params}`}
        className={'dialog-container'}
        width={modalStates.eventType === 'link' || modalStates.eventType === 'image' ? '500px' : '1000px'}
        height={modalStates.eventType === 'link' || modalStates.eventType === 'image' ? '240px' : '950px'}
        isModal={true}
        target='#container'
        header={setHeader(modalStates.eventType)}
        closeOnEscape={true}
        buttons={buttons}
        ref={dialog => dialogInstance.current = dialog!}
      >
        <div>
          {modalStates.eventType === 'link' || modalStates.eventType === 'image'
            ?
            getListRender(modalStates.eventType, 'new')
            :
            <Tab
              panes={panes}
              menu={{ text: true }}
            />
          }
        </div>
      </DialogComponent>
    </div>
  );
};


export default connect()(RichtextDialog);

