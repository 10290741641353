import styled from 'styled-components';

export const GanttContainerStyled = styled.div`

/* e-chart-scroll-container e-content */
   height: 100% !important;
   .e-chart-scroll-container,
   .e-chart-rows-container,
   .e-gantt-dependency-view-container,
   .e-chart-rows-container{
    height: 100% !important;
   }

  .gantt-task {
    background-color: #6873b5;
    border: 1px solid #6d619b;
    height: 100%;
    border-radius: 5px;
  }

  .e-task-label {
    position: absolute;
    top: 5px;
    font-size: 12px;
    text-overflow: "ellipsis";
    height: 90%;
    overflow: hidden;
  }

  .e-gantt-milestone {
    position: 'absolute' !important;
    background-color: transparent !important;
    margin: -3px 0 0 -4px !important;
  }

  .taskbar-container .e-milestone-top {
    border-bottom-color: #7ab748 !important;
    border-width: 12px;
  }

  .taskbar-container .e-milestone-bottom {
    border-top-color: #7ab748 !important;
    border-width: 12px;
  }

  .e-task-label {
    margin-top: -3px !important;
  }

  .e-weekend-header-cell {
    background-color: rgb(240, 240, 240) !important;
  }

  .e-gantt-chart > .e-chart-root-container {
    height: calc(100% - 64px) !important;
  }

  .e-gantt .e-gantt-chart .e-holiday,
  .e-gantt .e-gantt-chart .e-weekend {
    background-color: #f0f0f0 !important;
  }

  .e-gantt .e-rowcell,
  .e-gantt .e-headercell {
    padding: 0 7px !important;
    font-weight: 400 !important;
  }

  .e-gantt .e-icons.e-none {
    display: none !important;
  }
`;
