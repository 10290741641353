import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from '../utils/notification';
import store from '../store/store';
import { getThemes } from '../store/getThemes/action';
import { jwtDecrypt } from '../api/services/tools/service.jwtDecrypt';
import { run as runActions } from '../store/workspaces/workspaces/run';

const ProtectedRoute = ({ children }) => {
  const jwtString = window.location.pathname.split('app/')[1];

  useEffect(() => {
    const fetchData = async () => {
      if (jwtString) {
        try {
          const response = await jwtDecrypt(jwtString);
          runActions(response.data.key, response.data.id, response.data);
        } catch (error) {
          toast.error('Error fetching data:', error.message);
          throw error;
        }
      }
    };

    fetchData();
  }, []);

  if (!localStorage.getItem('session')) {
    return <Navigate to='/login' />;
  }
  store.dispatch<any>(getThemes());
  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.any
};

export default ProtectedRoute;
