import React, { FC } from 'react';
import { Divider, Grid } from 'semantic-ui-react';
import FormField from './Form.field';

// GLOBAL
const isLocked = (field) => {
  return (typeof (field) !== 'undefined' || field != null) ? field.locked : true;
};

const getWidths = (section) => {
  const numbers = [
    'zero', 'one', 'two', 'three', 'four', 'five',
    'six', 'seven', 'eight', 'nine', 'ten',
    'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen'
  ];

  const columns = section.fieldSets.map(
    fieldSet => {
      return { width: fieldSet.width };
    }
  );

  const sum = columns.reduce((acc, col) => acc + col.width, 0);
  const colsUpdated = columns.map(col => ({
    ...col,
    width: Math.round(col.width / sum * 16.0)
  }));
  const sumUpdated = colsUpdated.reduce((acc, col) => acc + col.width, 0);
  const colsFinal = colsUpdated.map(col => ({
    ...col,
    width: Math.round(col.width * (16.0 / sumUpdated))
  }));
  const classes = colsFinal.map(col => numbers[col.width]);
  return classes;
};

const Form: FC<any> = ({ renderData, data, params, editData, setFormEditData, setFormEditFile, viewType }) => {
  if (!renderData)
    return null;

  return (
    <>
      <Grid className="Grid">
        {renderData.sections.map(
          (section, i) => {
            const widths = getWidths(section);
            return (
              <Grid.Row columns={section.fieldSets.length} key={'Grid.RowOuter' + i}>
                {section.fieldSets.map((set, i) => (
                  <Grid.Column key={'Grid.Column' + i} className={`${widths[i]} wide column`}>
                    {set.fields.length === 0 ?
                      set.sections.map((section, i) => {
                        const widths = getWidths(section);
                        return (
                          <Grid.Row columns={section.fieldSets.length} key={'Grid.RowOuter' + i}>
                            {section.fieldSets.map((set, i) => (
                              <Grid.Column key={'Grid.Column' + i} className={`${widths[i]} wide column`}>
                                {set.fields.map((field, i) => (
                                  <Grid.Row key={'Grid.RowOuter' + i} className={`grid-row-outer ${field.type !== 'string' ? 'grid-row-inner' : ''}`}>
                                    {
                                      <FormField
                                        field={field}
                                        fieldLocked={isLocked(field)}
                                        key={'FormField-' + field._key}
                                        data={data}
                                        params={params}
                                        editData={editData}
                                        setFormEditData={setFormEditData}
                                        setFormEditFile={setFormEditFile}
                                        viewType={viewType}
                                        renderData={renderData}
                                      />
                                    }
                                  </Grid.Row>
                                ))}
                              </Grid.Column>
                            ))}
                          </Grid.Row>
                        );
                      }
                      ) :
                      set.fields.map((field, i) => (
                        <Grid.Row columns={1} key={'Grid.RowInner' + i}>
                          {
                            <FormField
                              field={field}
                              fieldLocked={isLocked(field)}
                              key={'FormField-' + field._key}
                              data={data}
                              params={params}
                              editData={editData}
                              setFormEditData={setFormEditData}
                              setFormEditFile={setFormEditFile}
                              viewType={viewType}
                              renderData={renderData}
                            />
                          }
                        </Grid.Row>
                      ))}
                  </Grid.Column>
                ))}
                <Divider fitted className="Divider" />
              </Grid.Row>
            );
          }
        )
        }</Grid>
    </>
  );
};

export default Form;