import cytoscape from 'cytoscape';

const cytoscapeStyle = (config) => {
  return cytoscape.stylesheet()
  //.selector('node[type = "foo"]')
    .selector('node')
    .css({
      'background-color': 'data(backgroundColor)',
      // 'background-image': 'data(backgroundImage)', 
      'width': 'data(width)',
      'height': 'data(height)',
      'color': 'data(color)',
      'border-color': 'data(borderColor)',
      'border-width': 'data(borderWidth)',
      'border-opacity': 0.5,
      'content': 'data(label)',
      'text-valign': 'center',
      'shape': 'data(shape)',
      'text-wrap': 'wrap',
      'text-max-width': 'data(width)',
      'font-size': config.style.font.size,
      'font-family': config.style.font.name
    })
    .selector('node.label-hidden')
    .css({
      'content': ''
    })
    .selector('node.background-hidden')
    .css({
      'width': 50,
      'height': 50,
      'shape': 'ellipse',
      'background-opacity': 0
    })
    .selector('node.border-hidden')
    .css({
      'border-opacity': 0
    })
    .selector('edge')
    .css({
      'width': 1,
      'line-color': 'data(lineColor)', //'grey',
      'target-arrow-color': 'data(targetArrowColor)', //'grey',
      'target-arrow-shape': config.style.edge.arrow ? 'data(targetArrowShape)' : 'none', //config.style.edge.arrow ? 'triangle' : 'none',
      'curve-style': 'bezier',
      'label': config.style.edge.label ? 'data(label)' : null,
      'edgeLength': '50px'
    })
    .selector('.eh-handle')
    .css({
      'border-width': 12, // makes the handle easier to hit
    })
    .selector('.eh-source')
    .css({
      'border-width': 2,
      'border-color': 'red'
    })
    .selector('.eh-target')
    .css({
      'border-width': 2,
      'border-color': 'red'
    })
    .selector('.eh-preview, .eh-ghost-edge')
    .css({
      'background-color': 'red',
      'line-color': 'red',
      'target-arrow-color': 'red',
      'source-arrow-color': 'red'
    })
    .selector('.eh-ghost-edge.eh-preview-active')
    .css({
      'opacity': 0
    })
    .selector('edge[label]')
    .css({
      //'opacity': 0,
      'font-size': '8px',
      'text-background-color': 'white',
      'text-background-opacity': 1,
      'text-background-padding': '2px',
      'text-border-color': 'black',
      'text-border-style': 'solid',
      'text-border-width': 0.5,
      'text-border-opacity': 1
    })
    .selector('node.selected')
    .css({
      'border-opacity': 0.7,
      'border-color': '#e12b38',
      'border-width': 3
    })
    .selector('node.selected.border-hidden')
    .css({
      'border-opacity': 0,
      'border-width': 0
    })
    .selector('edge.selected')
    .css({
      'line-opacity': 0.7,
      'width': 1,
      'text-border-color': '#e12b38',
      'text-border-width': 1,
      'text-border-opacity': 0.7,
      'line-color': '#e12b38',
      'target-arrow-color': '#e12b38'
    })
    .selector('node.hover')
    .css({
      'border-opacity': 0.7,
      'border-color': '#e12b38',
      'border-width': 3
    })
    .selector('node.hover.border-hidden')
    .css({
      'border-opacity': 0,
      'border-width': 0
    })
    .selector('edge.hover')
    .css({
      'line-opacity': 0.7,
      'width': 1,
      'text-border-color': '#e12b38',
      'text-border-width': 1,
      'text-border-opacity': 0.7,
      'line-color': '#e12b38',
      'target-arrow-color': '#e12b38'
    });
};

export default cytoscapeStyle;