import { jwtDecrypt } from '../tools/service.jwtDecrypt';
import request from '../../../request/request';
import { log } from '../../../utils/notification';

export const getWorkspacesPinnedAPI = async () => {
  try {
    const sessionData = JSON.parse(localStorage.getItem('session'));
    const { user } = (await jwtDecrypt(sessionData)).data;

    const response = await request<any, any>(true, 'POST', 'services/workspaces/get-pinned',   { user });

    return response;
  } catch (error) {
    log('services/workspaces/get-pinned', { response: error.message }, 'Error');
  }
};
