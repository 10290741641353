import { jwtDecrypt } from '../tools/service.jwtDecrypt';
import request from '../../../request/request';
import { log } from '../../../utils/notification';

export const setLastVisitAPI = async (params) => {

  try {
    const res = await jwtDecrypt(JSON.parse(localStorage.getItem('session')));
    const response = await request<Request.LastVisit, any>(true, 'POST', 'services/messenger/set-last-visit',  { user: res.data.user, id: params.id });

    return response;
  } catch (error) {
    log('setLastVisitAPI', { response: error.message }, 'Error');
  }
};
