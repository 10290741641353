export const openMultiUploadReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          modals: {
            ...instance.modals, 
            multiUpload: {
              isOpen: true,
              widgetData: action.widgetData
            }
          },
        };
      }
      return instance;
    }),
  };
  
};

export const closeMultiUploadReduce = (state) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          modals: {
            ...instance.modals, 
            multiUpload: {
              isOpen: false
            }
          },
        };
      }
      return instance;
    }),
  };
};

export const setTextCursorModalReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          modals: {
            ...instance.modals, 
            selectedText: action.selectedText
          },
        };
      }
      return instance;
    }),
  }; 
};

export const openRichtextModalReduce = (state, action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          modals: {
            ...instance.modals, 
            imageDialog: {
              isOpen: true,
              params: action.params
            },
            title: action.title,
            content: action.content,
            imageEvent: action.imageEvent,
            linkEvent: action.linkEvent,
            diagramEvent: action.diagramEvent,
            eventType: action.eventType
          },
        };
      }
      return instance;
    }),
  };  
};
  

export const closeRichtextModalReduce = (state) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          modals: {
            ...instance.modals, 
            imageDialog: {
              isOpen: false,
              params: null
            },
            title: null,
            content: null,
            imageEvent: null,
            linkEvent: null,
            eventType: null
          },
        };
      }
      return instance;
    }),
  };
};
    

export const openDialogReduce = (state,action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          modals: {
            ...instance.modals, 
            dialog: {
              isOpen: action.isOpen
            }
          },
        };
      }
      return instance;
    }),
  };
};
  

export const openModalReduce = (state,action) => {
  return {
    ...state,
    instances: state.instances.map((instance) => {
      if (instance.isActive) {
        return {
          ...instance,
          modals: {
            ...instance.modals, 
            modal: {
              isOpen: action.isOpen
            }
          },
        };
      }
      return instance;
    }),
  };
};
        
  
  

