import React, { FC } from 'react';
//import { useSelector } from 'react-redux'
import Base64Field from './fields/Field.base64';
import ListField from './fields/Field.list';
import SelectField from './fields/Field.select';
import BooleanFieldSync from './fields/Field.boolean';
import TextAreaFieldSync from './fields/Field.textArea';
import RichTextField from './fields/richtext/Field.richtext';
import CodeField from './fields/Field.code';
import FloatField from './fields/Field.float';
import PasswordField from './fields/Field.password';
// import BinaryField from './fields/BinaryField'
import DefaultField from './fields/Field.default';
import PasswordEditField from './fields/Field.passwordEdit';
import { Button } from 'semantic-ui-react';
import FileUploadField from './fields/Field.upload';
import EmailField from './fields/Field.email';
import HTMLField from './fields/Field.html';
import StringField from './fields/Field.string';
import IntegerField from './fields/Field.integer';
import DateTimeField from './fields/Field.dateTime';
import ColorPickerField from './fields/Field.colorPicker';
import StructuredListField from './fields/Field.structuredList';
import URLField from './fields/Field.url';

const FormField: FC<any> = ({ field, data, params, editData, setFormEditData, setFormEditFile, viewType, renderData }) => {

  if (!field || !data) {
    return null;
  }

  const setNewValue = (value) => {
    const obj = { _key: field.attribute.key };
    obj[field.attribute.name] = value;
    setFormEditData(obj);
  };

  const setNewFileToUpload = (file: any, hash: string) => {
    setNewValue(hash);
    setFormEditFile(file);
  };

  const setNewOptionsValue = (option) => {
    const obj = { _key: field.attribute.key };
    obj[field.attribute.name] = { ...option, selected: true };
    setFormEditData(obj);
  };

  const getIsLocked = (field) => {
    if (viewType === 'view') return true;
    return field.locked ? field.locked : false;
  };

  const commonProps = {
    field: field,
    isLocked: getIsLocked(field)
  };

  switch (field.type) {
    case 'string':
      return (
        <StringField
          {...commonProps}
          value={getValue(field, data, editData)}
          setNewValue={setNewValue}
        />
      );

    case 'list':
      return (
        <ListField
          {...commonProps}
          setFormEditData={setNewOptionsValue}
          options={data[decapitalize(field.attribute.name + '')]}
        />
      );

    case 'select':
      return (
        <SelectField
          {...commonProps}
          value={editData[decapitalize(field.label + '')]}
          setNewValue={setNewValue}
          options={data[decapitalize(field.attribute.name + '')]}
        />
      );

    case 'multiSelect':
      return (
        <SelectField
          {...commonProps}
          value={editData[decapitalize(field.label + '')]}
          setNewValue={setNewValue}
          options={data[decapitalize(field.attribute.name + '')]}
        />
      );

    case 'integer':

      if (field.label === 'ID') {
        return (
          <IntegerField
            {...commonProps}
            value={getValue(field, data, null)}
            setNewValue={setNewValue}
          />
        );
      }

      return (
        <IntegerField
          {...commonProps}
          value={getValue(field, data, editData)}
          setNewValue={setNewValue}
        />
      );
    case 'timestamp':
    case 'date':
    case 'datetime':
      return (
        <DateTimeField
          {...commonProps}
          value={getValue(field, data, editData)}
          setNewValue={setNewValue}
        />
      );

    case 'boolean':
      return (
        <BooleanFieldSync
          {...commonProps}
          value={getValue(field, data, editData)}
          setNewValue={setNewValue}
        />
      );
    case 'textarea':
      return (
        <TextAreaFieldSync
          {...commonProps}
          value={getValue(field, data, editData)}
          setNewValue={setNewValue}
        />
      );
    case 'richtext':
      return (
        <RichTextField
          {...commonProps}
          viewType={viewType}
          value={getValue(field, data, editData)}
          params={params}
          setNewValue={setNewValue}
          renderData={renderData}
        />
      );
    case 'markdown':
      return (
        <RichTextField
          {...commonProps}
          viewType={viewType}
          value={getValue(field, data, editData)}
          params={params}
          setNewValue={setNewValue}
          renderData={renderData}
        />
      );
    case 'cypher':
    case 'diagram':
      return (
        <CodeField
          {...commonProps}
          viewType={viewType}
          value={getValue(field, data, editData)}
          setNewValue={setNewValue}
        />
      );
    case 'code':
      return (
        <CodeField
          {...commonProps}
          viewType={viewType}
          value={getValue(field, data, editData)}
          setNewValue={setNewValue}
        />
      );
    case 'HTML':
      return (
        <HTMLField
          {...commonProps}
          viewType={viewType}
          value={getValue(field, data, editData)}
        />
      );
    case 'color':
      return (
        <ColorPickerField
          {...commonProps}
          value={getValue(field, data, editData)}
          setNewValue={setNewValue}
        />
      );
    case 'float':
      return (
        <FloatField
          {...commonProps}
          value={getValue(field, data, editData)}
          setNewValue={setNewValue}
        />
      );
    case 'json':
      return (
        <CodeField
          {...commonProps}
          viewType={viewType}
          value={getValue(field, data, editData)}
          setNewValue={setNewValue}
        />
      );
    case 'url':
      return (
        <URLField
          {...commonProps}
          value={getValue(field, data, null)}
          setNewValue={setNewValue}
        />
      );

    case 'email':
      return (
        <EmailField
          {...commonProps}
          value={getValue(field, data, null)}
          setNewValue={setNewValue}
        />
      );
    case 'password':
      switch (viewType) {
        case 'view':
          return (
            <PasswordField
              {...commonProps}
              value={getValue(field, data, editData)}
            />
          );
        case 'edit':
          return (
            <PasswordEditField
              setNewValue={setNewValue}
            />
          );
        case 'create':
          return (
            <PasswordEditField
              setNewValue={setNewValue}
            />
          );
        default:
          return <span>viewType nicht gesetzt</span>;
      }

    case 'base64':
      return (<Base64Field
        {...commonProps}
        value={getValue(field, data, editData)}
      />);

    case 'button':
      return (
        <Button
          {...commonProps}
          label={field.label}
          value={getValue(field, data, editData)}
        />
      );
    case 'binary':
      switch (viewType) {
        case 'view':
          return (<Base64Field
            {...commonProps}
            value={getValue(field, data, editData)}
          />);
        case 'edit':
          if (field.locked === true) {
            return (
              <Base64Field
                {...commonProps}
                value={getValue(field, data, editData)}
              />);
          } else {
            return (
              <FileUploadField
                {...commonProps}
                setNewFileToUpload={setNewFileToUpload}
              />
            );
          }
        case 'create':
          return (
            <FileUploadField
              {...commonProps}
              setNewFileToUpload={setNewFileToUpload}
            />
          );
        default:
          return <span>viewType nicht gesetzt</span>;
      }
    case 'structured list':
      return (
        <StructuredListField
          {...commonProps}
          data={data}
          setNewValue={setNewValue}
        />
      );


    default:
      return (<DefaultField field={field} />);
  }
};

const getValue = (field, data, editData) => {
  try {
    if (!data) {
      return null;
    }

    const foundInEditData = editData ? editData.find(o => Object.prototype.hasOwnProperty.call(o, field.attribute.name)) : false;

    if (field.attribute.name === 'id') {
      return data.id;
    }

    if (foundInEditData) {
      return foundInEditData[field.attribute.name];
    } else {
      if (data[field.attribute.name] == null) {
        return '';
      } else {
        return data[field.attribute.name];
      }
    }
  } catch {
    // log(field.name, { respsone: error.message }, 'Error');
  }

};

const decapitalize = (string) => {
  if (typeof string === 'string') {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
};

export default FormField;