import { log, toast } from '../../../utils/notification';
import request from '../../../request/request';


export const verifySessionToken = async (token) => {
  try {
    return await request<Request.VerifyToken, any>(false, 'POST', 'public/services/verify-session',  { token });  
  } catch (error) {
    toast.error('Request', error.message);
    log('Request (type: verifySessionToken)', { response: error.message }, 'Error');
  }
};
