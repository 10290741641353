import { log } from '../../../utils/notification';
import { runStaticQuery } from '../run.staticQuery';
import { jwtDecrypt } from '../../services/tools/service.jwtDecrypt';

export const getBreadcrumbItem = async (storeName: string, key: number, id: number) => {
  let userUUID = null;

  await jwtDecrypt(JSON.parse(localStorage.getItem('session')))
    .then((response) => {
      userUUID = response.data.user.uuid;
    })
    .catch((error) => {
      console.log('jwtDecrypt error', error);
    });


  try {
    const parameters = { user: userUUID, key: key, id: id };
    return runStaticQuery('breadcrumb', parameters);
  } catch (error) {
    log('StoredProcedure (alias: getBreadcrumbItem)', { params: { key: key, id: id }, response: { message: error.message } }, 'Error');
  }
};