import request from '../request/request';
import { log } from './notification';

const checkDBConnection = async () => {
  try {
    const response = await request<any, any>(false, 'POST', 'public/check-db-connection', {});
    return response;
  } catch (error) {
    log('Request (type: check-db-connection)', { response: error.message }, 'Error');
  }
};

export default checkDBConnection;
